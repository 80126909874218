import React from "react";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook
import "../components/styles/ProductQuality.css";
import quality1 from "../assets/quality/1-1.JPG";
import quality2 from "../assets/quality/1-2.jpg";
import control1 from "../assets/quality/2-1.jpg";
import control2 from "../assets/quality/2-2.JPG";
import certification1 from "../assets/quality/3-1.jpg";
import certification2 from "../assets/quality/3-2.png";

const ProductQuality = () => {
  const { t } = useTranslation(); // Initializing the translation function

  return (
    <div className="quality-page">
      <h1 className="titleQ">{t("productQuality.title")}</h1>
      <p className="intro">{t("productQuality.intro")}</p>

      {/* Modern Technologies Section */}
      <section className="section">
        <h2 className="section-title">{t("productQuality.modernTechnologies.title")}</h2>
        <p className="section-text">{t("productQuality.modernTechnologies.description")}</p>
        <div className="images">
          <img src={quality1} alt={t("productQuality.modernTechnologies.imageAlt1")} />
          <img src={quality2} alt={t("productQuality.modernTechnologies.imageAlt2")} />
        </div>
      </section>

      {/* Strict Control Section */}
      <section className="section">
        <h2 className="section-title">{t("productQuality.strictControl.title")}</h2>
        <p className="section-text">{t("productQuality.strictControl.description")}</p>
        <div className="images">
          <img src={control2} alt={t("productQuality.strictControl.imageAlt2")} />
        </div>
      </section>

      {/* Certification Section */}
      <section className="section">
        <h2 className="section-title">{t("productQuality.certification.title")}</h2>
        <p className="section-text">{t("productQuality.certification.description")}</p>
        <div className="images">
          <img src={certification1} alt={t("productQuality.certification.imageAlt1")} />
          <img src={certification2} alt={t("productQuality.certification.imageAlt2")} />
        </div>
      </section>

      {/* Customer Trust Section */}
      <section className="section">
        <h2 className="section-title">{t("productQuality.customerTrust.title")}</h2>
        <p className="section-text">{t("productQuality.customerTrust.description")}</p>
      </section>

      {/* PDF Attachments */}
      <section className="section">
        <h2 className="section-title">{t("productQuality.additionalResources.title")}</h2>
        <p className="section-text">{t("productQuality.additionalResources.description")}</p>
        <ul className="pdf-list">
          {[...Array(9).keys()].map((index) => {
            const pdfName = `4-${index + 1}.pdf`;
            return (
              <li key={index}>
                <a
                  href={`/pdf/${pdfName}`}
                  download={pdfName}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("productQuality.additionalResources.downloadText", { pdfName })}
                </a>
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
};

export default ProductQuality;
