import React, {useState, useEffect} from 'react';

import './App.css';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from './pages/Home'
import Menu from "./components/Menu.js"
import About from "./pages/About.js"
import Contact from "./pages/Contact.js"
import Description from "./components/Description.js";
import DeliveryPayment from "./pages/DelivertPayment.js"
import ExchangeReturns from "./pages/ExchangeReturns.js"
import History from "./pages/history.js"
import Quality from "./pages/ProductQuality.js"

import NavbarMobile from './pages/NavbarMobile.js'; // Import the mobile navbar component
import './i18n';
import Addresses from "./pages/Adresses.js";
import Feedback from "./pages/Feedback.js";
import Directions from "./pages/Directions.js";

import {Helmet} from "react-helmet";

import Mastitest from './pages/Mastitest';
import Lasota from './pages/Lasota';
import Sibirka from './pages/Sibirka';
import Tuberkulin from './pages/Tuberkulin';
import Mixovak from './pages/Mixovak';
import TuberkulinPure from './pages/TuberkulinPure';
import VirusVaccinePig from './pages/VirusVaccinePig';
import Deratez from './pages/Deratez';
import Alum from './pages/Aluminiy';
import Pasterelioz from './pages/Pasterelioz';
import Universal from './pages/Universal';
import DZPT2 from './pages/DZPT2';
import Collab from "./pages/Collab.js";
import JobPosting from "./pages/JobPosting.js";
import Advice from "./pages/Advice.js";

import News from "./pages/news.js";







function App() {
    const [isMobile, setIsMobile] = useState(false);

    // Check the screen size and update the state
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="App">
            <Helmet>
                <title>Сумська біологічна фабрика</title>
                <meta name="description"
                      content="Сумська біологічна фабрика – український виробник біопрепаратів з 90-річною історією."/>
            </Helmet>
            <Router>
                {isMobile ? <NavbarMobile/> : <Navbar/>}
                <Routes>
                    {/*меняет текущую страницу на ту которую указали*/}
                    <Route path="/" element={<Home/>}/>

                    <Route path="/menu" element={<Menu/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/description/:id" element={<Description/>}/>
                    <Route path="/payment" element={<DeliveryPayment/>}/>
                    <Route path="/exchange" element={<ExchangeReturns/>}/>
                    <Route path="/about/history" element={<History/>}/>
                    <Route path="/about/certificates" element={<Quality/>}/>
                    <Route path="/contacts/addresses" element={<Addresses/>}/>
                    <Route path="recommendations/reviews" element={<Feedback/>}/>
                                        <Route path="recommendations/collaboration" element={<Collab/>}/>

                    <Route path="/about/vacancies" element={<JobPosting/>}/>
                    <Route path="recommendations/tips" element={<Advice/>}/>

                    <Route path="contacts/map" element={<Directions/>}/>
                    <Route path="/description/5" element={<Mastitest/>}/>
                    <Route path="/description/6" element={<Lasota/>}/>
                    <Route path="/description/7" element={<Sibirka/>}/>
                    <Route path="/description/8" element={<Tuberkulin/>}/>
                    <Route path="/description/9" element={<Mixovak/>}/>
                    <Route path="/description/10" element={<TuberkulinPure/>}/>
                    <Route path="/description/11" element={<VirusVaccinePig/>}/>
                    <Route path="/description/12" element={<Deratez/>}/>
                    <Route path="/description/13" element={<Pasterelioz/>}/>
                    <Route path="/description/14" element={<Universal/>}/>
                    <Route path="/description/15" element={<DZPT2/>}/>
                         <Route path="/description/18" element={<Alum/>}/>
                                        <Route path="/about/news" element={<News/>}/>


                </Routes>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;