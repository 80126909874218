import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import translationEN from './translationENG.json';
import translationUKR from './translationUKR.json';
const getFallbackLanguage = (lng) => {
  // Check for all variations of Russian language codes
  const russianLanguageCodes = ['ru', 'ru-RU', 'ru-ru', 'ru_rU', 'ru_ru', 'RU', 'RU-RU', 'PL', 'pl'];

  if (russianLanguageCodes.includes(lng)) {
    return 'ukr';
  }

  return 'en';
};
// Define the resources
const resources = {
  en: {
    translation: translationEN
  },
  ukr: {
    translation: translationUKR
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: (code) => getFallbackLanguage(code),
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
