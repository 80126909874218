import React, { useState } from 'react';
import {  useNavigate,Link } from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Tabl from "../assets/tabl.png";
import Cat from "../assets/newPhoto/mastitest.jpg";

function Mastitest() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };
const handleDownload = () => {
  const pdfPath = "/pdf/pdf_vkladki/ЛИСТІВКА-ВКЛАДКА.МАСТИТЕСТ-С.PDF"; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };

  return (
      <div className="descriptionPage">
        <div className="productDetails">
          <div className={"title_image"}>
            <h1>{t("Mastitest-S")}</h1>
            <img src={Cat} alt="Mastitest-S" className="productImage"/>
          </div>
          <div className="productExtraInfo">
            <p>
              <strong>{t("Product Name")}:</strong>{" "}
              {t("Mastitest-S Test System for Determining Somatic Cells in Milk")}
            </p>
            <p><strong>{t("Price per vial (100 doses)")}</strong>: 81.60 {t("UAH")}</p>
            <p><strong>{t("Minimum order")}</strong>: {t("1 vial")}</p>
            <p><strong>{t("Availability")}</strong>: {t("In stock")}</p>

            <p><strong>{t("Price per vial (1000 doses)")}</strong>: 726,00 {t("UAH")}</p>
            <p><strong>{t("Minimum order")}</strong>: {t("1 vial")}</p>
            <p><strong>{t("Availability")}</strong>: {t("Out of stock")}</p>
            <p>
              <strong>{t("Composition")}:</strong>{" "}
              {t("Sulfonol 10%, Bromcresol Purple, Distilled Water")}
            </p>
            <p>
              <strong>{t("Pharmaceutical Form")}:</strong>{" "}
              {t(
                  "Cherry-violet liquid containing surfactant and indicator. A slight sediment may form at the bottom of the bottle. Available as a 10% solution."
              )}
            </p>
            <p>
              <strong>{t("Pharmacological Properties")}:</strong>{" "}
              {t(
                  "The test system’s action is based on the interaction of sulfonol with somatic cells' nuclei (mainly leukocytes) present in milk during inflammatory processes of the udder (mastitis) and the change in milk pH, which is determined using bromcresol purple."
              )}
            </p>
            <p>
              <strong>{t("Mechanism of Action")}:</strong>{" "}
              {t(
                  "Sulfonol interacts with somatic cells (mostly leukocytes) in milk, creating a gel-like clot of varying density. The more somatic cells present, the denser the clot."
              )}
            </p>
            <p>
              <strong>{t("Animal Type")}:</strong>{" "}
              {t("Cattle (cows), small ruminants (sheep, goats).")}
            </p>
            <p>
              <strong>{t("Indications for Use")}:</strong>{" "}
              {t(
                  "This product is intended for diagnosing hidden mastitis and assessing treatment results in cows, goats, and sheep."
              )}
            </p>
            <p>
              <strong>{t("Precautions")}:</strong>{" "}
              {t(
                  "Do not use if expired or if the bottle is unlabeled. The test system may form sediment when stored below 10°C, but this does not affect its quality. If sediment appears, heat the bottle in a water bath and shake until the sediment dissolves. Shake the bottle before use."
              )}
            </p>
            <p>
              <strong>{t("Application Method")}:</strong>
            </p>
            <ol>
              <li>
                {t(
                    "The test is performed using milk control plates (MCP) or similar."
                )}
              </li>
              <li>
                {t(
                    "Place 1 ml of milk into a plate well, add 1 ml of 10% Mastitest-S, and mix for 15–20 seconds. Observe changes in gel density and color."
                )}
              </li>
              <li>
                {t(
                    "For doubtful results, test milk from each udder quarter using a 2% Mastitest-S solution. Mix 1 ml milk with 1 ml of 2% solution for 15–20 seconds and evaluate."
                )}
              </li>

            </ol>
            <img src={Tabl} alt={t("Test System Procedure")} className="productImageTabl"/>
            <p>
              <strong>{t("Result Control")}:</strong>{" "}
              {t(
                  "Milk contains somatic cells when positive or doubtful reactions occur with both 10% and 2% solutions."
              )}
            </p>
            <p>
              <strong>{t("Caution")}:</strong>{" "}
              {t("Do not mix with other chemicals or freeze.")}
            </p>
            <p>
              <strong>{t("Shelf Life")}:</strong>{" "}
              {t("12 months from manufacturing date.")}
            </p>
            <p>
              <strong>{t("Storage and Transportation Conditions")}:</strong>{" "}
              {t(
                  "Store in a light-protected place at temperatures between 5°C and 25°C."
              )}
            </p>
            <p>
              <strong>{t("Packaging")}:</strong>{" "}
              {t("Available in bottles of 50 ml, 100 ml, and 200 ml.")}
            </p>
            <p>
              <strong>{t("Release Conditions")}:</strong>{" "}
              {t("Over the counter.")}
            </p>
            <p>
              <strong>{t("Manufacturer Information")}:</strong>{" "}
              {t(
                  'State Enterprise "Sumy Biological Factory", Gamaliya St. 25, Sumy, Ukraine, 40021. Phone/Fax: (0542) 770-065'
              )}
            </p>
            <div className="productDownloadRegistrationCert">
              <button onClick={handleDownload} className="productDownloadRegistrationCert">
                {t("Download Product Registration")}
              </button>
            </div>

            <div className="productDownloadRegistrationCert">
              <Link to="/contact" className="productDownloadRegistrationCert">
                {t("Check availability")}
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar">
          <h2>{t("Search the Product")}</h2>
          <label>
            {t("Animal")}:
            <select
                value={selectedAnimal}
                onChange={(e) => setSelectedAnimal(e.target.value)}
            >
              <option value="all">{t("another")}</option>

              <option value="pig">{t("Pig")}</option>
              <option value="cattle">{t("Cattle")}</option>

              <option value="poultry">{t("Poultry")}</option>


              <option value="rabbit">{t("Rabbit")}</option>

              <option value="rodent">{t("Rodents")}</option>

            </select>
          </label>
          <label>
            {t("Category")}:
            <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="all">{t("All")}</option>
              <option value="vaccines">{t("Vaccines")}</option>
              <option value="diagnostics">{t("Diagnostics")}</option>
              <option value="rodenticides">{t("Rodenticides")}</option>
              <option value="test-kits">{t("Test Kits")}</option>
              <option
                  value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
              <option value="experimental-drugs">{t("Experimental Drugs")}</option>

            </select>
          </label>
          <label>
            {t("Product Name")}:
            <input
                type="text"
                placeholder={t("Enter product name")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </label>
          <label>
            {t("Active Ingredient")}:
            <input
                type="text"
                placeholder={t("Enter active ingredient")}
                value={activeIngredient}
                onChange={(e) => setActiveIngredient(e.target.value)}
            />
          </label>
          <label>
            {t("Pharmaceutical Form")}:
            <input
                type="text"
                placeholder={t("Enter pharmaceutical form")}
                value={pharmaceuticalForm}
                onChange={(e) => setPharmaceuticalForm(e.target.value)}
            />
          </label>
          <button onClick={handleReset} style={{marginBottom: "10px"}}>
            {t("Reset Search")}
          </button>
          <button onClick={handleSearchClick}>{t("Search")}</button>
        </div>
      </div>
  );
}

export default Mastitest;
