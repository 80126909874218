import FacebookIcon from '@mui/icons-material/Facebook';
import "../components/styles/Footer.css";
import logo from "../assets/ЛОГО_NEW.png";
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation
import React, { useState, useEffect } from "react";

function Footer() {
    const { t } = useTranslation(); // Access translation function
    const [isPhoneScreen, setIsPhoneScreen] = useState(false); // State to track screen size
    useEffect(() => {
        // Check screen size and set the state
        const mediaQuery = window.matchMedia("(max-width: 768px)");
        const handleScreenSizeChange = () => setIsPhoneScreen(mediaQuery.matches);

        // Attach event listener for screen size changes
        mediaQuery.addEventListener("change", handleScreenSizeChange);

        // Initialize state on component mount
        setIsPhoneScreen(mediaQuery.matches);

        // Cleanup listener on component unmount
        return () => mediaQuery.removeEventListener("change", handleScreenSizeChange);
    }, []);
    return (
        <div className="footer">
            <div className={"privatePolicy"}>
                <img src={logo} alt={t("Logo")} className="logo" />
                <p>{t("Privacy Policy")}</p>
                <p>&copy; 2024 - {t("All rights reserved")}</p>
            </div>
            <div className="CenterSide">
                <div className={"alignmentStart"}>
                    <p className="mainMenu">{t("Main Menu")}</p>

                    <div className="rightSideFooter">
                        <Link to="/">{t("HOME")}</Link>
                        <Link to="/menu">{t("PRODUCTS")}</Link>
                        <Link to="/about/history">{t("ABOUT US")}</Link>
                        <Link to="/contact">{t("CONTACT")}</Link>
                    </div>
                </div>

                <div className="socialMedia">
                    <a href="https://www.facebook.com/sumy.biofabrika/" target="_blank" rel="noopener noreferrer">
                        <FacebookIcon />
                    </a>
                </div>
            </div>
            <div className={"contacts"}>
                <p className={"colName"}>{t("Contacts")}</p>

                <>
                    <p>
                        <SmartphoneIcon/> (068)-420-35-45 / (073)-420-35-45
                    </p>
                    <p><SmartphoneIcon/> (0542)-61-70-08 / (099)-420-35-45 </p>
                    <p><SmartphoneIcon/> +38(0542)-61-70-32 {t("director")} </p>

                </>

                <p>
                    <EmailIcon/> sgbiofabrika@gmail.com
                </p>
            </div>
        </div>
    );
}

export default Footer;
