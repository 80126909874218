import React, {useState} from "react";
import {t} from 'i18next';
import {useTranslation} from "react-i18next";
import BannerImage from "../assets/Rabbits.JPG";
import "../components/styles/Feedback.css"; // Import the corresponding CSS

const Advice = () => {
    const [expandedChapter, setExpandedChapter] = useState(null);

 const handleChapterToggle = (chapter) => {
    setExpandedChapter(expandedChapter === chapter ? null : chapter);
  };

    const {t} = useTranslation();
    const pdfNames = [
        'ЛИСТІВКА-ВКЛАДКА_БІСПОРИН.PDF',
        'ЛИСТІВКА-ВКЛАДКА_ДЗПТ-2.PDF',
        'ЛИСТІВКА_ВКЛАДКА_ААМ.PDF',
        'ЛИСТІВКА-ВКЛАДКА_ЕМКАР.PDF',
        'ЛИСТІВКА-ВКЛАДКА_ЛА-СОТА.PDF',
        'ЛИСТІВКА-ВКЛАДКА_МАСТИТЕСТ-С.PDF',
        'ЛИСТІВКА-ВКЛАДКА_ПАСТЕРЕЛЬОЗ.PDF',
        'ЛИСТІВКА-ВКЛАДКА_СИБІРКА.PDF',
        'ЛИСТІВКА-ВКЛАДКА_ТУБ_ПТИЦІ.PDF',
        'ЛИСТІВКА-ВКЛАДКА_ТУБ_ССАВЦІ.PDF',
        'ЛИСТІВКА-ВКЛАДКА_ЧУМА_СВИНЕЙ.PDF',
        'ЛИСТІВКА-ВКЛАДКА_ДЕРАТЕЗ.PDF'

    ];

    return (
        <div className={"feedback-container"}>
            <h2>{t("Animal Disease Prevention")}</h2>

            <h2>{t("General Recommendations")}</h2>


            {/*<h3>{t("Advice on Specific Diseases:")}</h3>*/}





            <h1 className="chapter-header"
                onClick={() => handleChapterToggle("ProperFeedingandWatering")}
                style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
            >{t("Proper Feeding and Watering")}{expandedChapter === "ProperFeedingandWatering" ? "▼" : "▶"}</h1>
            {expandedChapter === "ProperFeedingandWatering" && (
                <div>
                    <h2>{t("Introduction")}</h2>
                    <p>{t("Proper feeding and watering are the foundation of livestock health and productivity. A balanced diet affects growth, development, immunity, and product quality (milk, meat, eggs, etc.). This section helps farmers create optimal diets for their animals, considering their needs and characteristics.")}</p>

                    <h2>{t("Key Feeding Principles")}</h2>
                    <ul>
                        <li>{t("Balanced diet: The diet should contain all necessary nutrients (proteins, fats, carbohydrates, vitamins, minerals) in proper proportions.")}</li>
                        <li>{t("Individual approach: Feed requirements differ for various animal species, ages, and physiological conditions.")}</li>
                        <li>{t("Feed quality: Feed should be fresh, clean, and safe.")}</li>
                        <li>{t("Feeding schedule: It's important to follow a feeding routine to avoid overfeeding or underfeeding.")}</li>
                        <li>{t("Clean water: Animals must have constant access to fresh, clean water.")}</li>
                    </ul>

                    <h2>{t("Factors Affecting Diet")}</h2>
                    <ul>
                        <li>{t("Animal species: Each species has unique digestive traits and feed needs.")}</li>
                        <li>{t("Age: Young animals require different feed than adults.")}</li>
                        <li>{t("Physiological state: Pregnancy, lactation, and illness affect feed requirements.")}</li>
                        <li>{t("Productivity: High-producing animals need more caloric diets.")}</li>
                        <li>{t("Season: Feed needs may vary by season.")}</li>
                    </ul>

                    <h2>{t("Diet Components")}</h2>
                    <ul>
                        <li>{t("Roughage: Hay, straw, silage – provides fiber needed for normal digestion.")}</li>
                        <li>{t("Succulent feed: Root crops, melons – high in water content and easily digestible.")}</li>
                        <li>{t("Concentrates: Grains, meal, oilcakes – sources of energy and protein.")}</li>
                        <li>{t("Mineral supplements: Salt, bone meal – compensate for mineral deficiencies.")}</li>
                        <li>{t("Vitamin supplements: Provide essential vitamins to animals.")}</li>
                    </ul>

                    <h2>{t("Tips for Creating a Diet")}</h2>
                    <ul>
                        <li>{t("Consult a veterinarian: Always consult a vet before changing an animal's diet.")}</li>
                        <li>{t("Feed analysis: Regularly test feed for nutrient content.")}</li>
                        <li>{t("Monitor animals: Observe health, appetite, and productivity.")}</li>
                        <li>{t("Gradual feed changes: Avoid sudden dietary changes to prevent digestive issues.")}</li>
                    </ul>

                    <h2>{t("Watering Animals")}</h2>
                    <ul>
                        <li>{t("Clean water: Water should always be fresh, clean, and accessible.")}</li>
                        <li>{t("Regular water changes: In warm weather, change water several times a day.")}</li>
                        <li>{t("Clean drinkers: Drinkers should be regularly cleaned and disinfected.")}</li>
                    </ul>

                    <h2>{t("Conclusion")}</h2>
                    <p>{t("Proper feeding and watering are critical factors affecting livestock health and productivity. Following the recommendations in this section will help you provide your animals with everything they need for a healthy and productive life.")}</p>
                    <div className="bannerRabbit">
                        <img src={BannerImage} alt="Cows" className="RabbitImage"/>
                    </div>
                                    </div>

                    )}
            <div>
                <h1
                    className="chapter-header"
                    onClick={() => handleChapterToggle("MaintainingSanitaryNorms")}
                    style={{cursor: "pointer", color: "#007BFF", fontSize: 24}}
                >{t("Maintaining Sanitary Norms")}{expandedChapter === "MaintainingSanitaryNorms" ? "▼" : "▶"}</h1>
                {expandedChapter === "MaintainingSanitaryNorms" && (
                    <div>
                        <p>{t("Maintaining sanitary norms is the foundation of disease prevention in animals. This is a set of measures aimed at keeping the living environment of animals clean and safe while preventing the spread of infections.")}</p>

                        <h2>{t("Key Aspects of Maintaining Sanitary Norms")}</h2>
                        <ul>
                            <li>
                                <strong>{t("Regular Cleaning")}</strong>
                                <ul>
                                    <li>{t("Daily cleaning of feeding and resting areas for animals.")}</li>
                                    <li>{t("Periodic disinfection of surfaces using special agents.")}</li>
                                    <li>{t("Removal of feces and food leftovers.")}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t("Clean Water")}</strong>
                                <ul>
                                    <li>{t("Ensure animals have access to fresh, clean water.")}</li>
                                    <li>{t("Regularly change the water in drinking containers.")}</li>
                                    <li>{t("Disinfect drinking containers.")}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t("Disinfection of Equipment")}</strong>
                                <ul>
                                    <li>{t("Regular disinfection of bowls, trays, cages, and other equipment.")}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t("Isolation of Sick Animals")}</strong>
                                <ul>
                                    <li>{t("If a sick animal is detected, it must be immediately isolated from healthy ones.")}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t("Pest Control")}</strong>
                                <ul>
                                    <li>{t("Regular monitoring and elimination of parasites such as fleas, ticks, and lice.")}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t("Preventive Treatment")}</strong>
                                <ul>
                                    <li>{t("Regular treatment of animals against parasites and external fungal infections.")}</li>
                                </ul>
                            </li>
                        </ul>

                        <h2>{t("Why is Maintaining Sanitary Norms Important?")}</h2>
                        <ul>
                            <li>{t("Reducing the risk of infections: Most infectious diseases spread through contaminated water, food, or contact with sick animals.")}</li>
                            <li>{t("Boosting immunity: A clean environment strengthens the immune system of animals.")}</li>
                            <li>{t("Preventing allergic reactions: Dirt and dust can cause allergic reactions in animals.")}</li>
                            <li>{t("Prolonging life: Following sanitary norms helps increase the lifespan and improve the quality of life for animals.")}</li>
                        </ul>

                        <p>
                            <strong>{t("Conclusion")}</strong> {t("Maintaining sanitary norms is one of the most effective ways to prevent diseases in animals. It is not only a responsible approach to your pet's health but also a way to prevent the spread of infections.")}
                        </p>
                    </div>
                )}
                <h1 className="chapter-header"
                    onClick={() => handleChapterToggle("Checkups")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Regular Veterinary Checkups")}{expandedChapter === "Checkups" ? "▼" : "▶"}</h1>
                {expandedChapter === "Checkups" && (
                    <div>

                        <p>{t("Regular veterinary checkups are an integral part of comprehensive disease prevention in animals. Even if your pet looks healthy, regular visits to the veterinarian will help detect diseases at early stages when they are easier to treat.")}</p>

                        <h2>{t("Why are Regular Veterinary Checkups Important?")}</h2>
                        <ul>
                            <li>{t("Early diagnosis: Many diseases in the initial stages are asymptomatic or have non-specific symptoms that the owner might not notice.")}</li>
                            <li>{t("Preventive vaccinations: The veterinarian will create an optimal vaccination schedule for your pet.")}</li>
                            <li>{t("Preventive deworming: Regular treatment against parasites helps prevent many health problems.")}</li>
                            <li>{t("Nutritional consultation: The veterinarian will help you develop a balanced diet for your pet.")}</li>
                            <li>{t("Weight control: Regular weigh-ins help identify excessive weight gain or malnutrition.")}</li>
                            <li>{t("Dental checkups: Oral diseases can lead to serious health issues.")}</li>
                            <li>{t("Skin and coat inspection: The veterinarian will assess the condition of the skin and coat, identifying possible allergies or skin diseases.")}</li>
                        </ul>

                        <h2>{t("How Often Should Checkups be Conducted?")}</h2>
                        <p>{t("The frequency of veterinary checkups depends on the age, breed, and general health of the animal. As a rule, younger animals are examined more often than adults. For healthy adult animals, it is recommended to conduct checkups 1-2 times a year. If the animal has chronic illnesses or belongs to a high-risk group, checkups may be required more frequently.")}</p>

                        <h2>{t("What Happens During a Veterinary Checkup?")}</h2>
                        <ul>
                            <li>{t("The veterinarian evaluates the overall health of the animal.")}</li>
                            <li>{t("Performs a physical examination: measuring temperature, listening to the heart and lungs, and checking the eyes, ears, nose, mouth, skin, and coat.")}</li>
                            <li>{t("Takes samples of blood, urine, or feces for tests.")}</li>
                            <li>{t("Performs vaccinations (if needed).")}</li>
                            <li>{t("Provides recommendations for pet care.")}</li>
                        </ul>

                        <p>
                            <strong>{t("Conclusion")}</strong> {t("Regular veterinary checkups are an essential part of responsible pet ownership. They allow for early detection of diseases and ensure a long, healthy, and happy life for your pet.")}
                        </p>
                    </div>
                )}
                <h1 className="chapter-header"
                    onClick={() => handleChapterToggle("Section")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Scheduled Vaccination")}{expandedChapter === "Section" ? "▼" : "▶"}</h1>
                {expandedChapter === "Section" && (
                    <div>

                        <h3>{t("Scheduled Vaccination")}</h3>
                        <p>{t("Vaccination is one of the most effective methods for preventing many infectious diseases in animals. It helps develop strong immunity in animals against disease pathogens, significantly reducing the risk of illness and mortality.")}</p>

                        <h4>{t("Why is Scheduled Vaccination Important?")}</h4>
                        <ul>
                            <li>{t("Immunity Development: Vaccination stimulates the animal's immune system to produce antibodies against specific disease pathogens, providing reliable protection.")}</li>
                            <li>{t("Prevention of Epidemics: Mass vaccinations help prevent outbreaks of infectious diseases.")}</li>
                            <li>{t("Health Preservation: Vaccination helps maintain the animal's health and prevents complications after recovering from diseases.")}</li>
                        </ul>

                        <h4>{t("Vaccination Schedule")}</h4>
                        <p>{t("The vaccination schedule is developed by a veterinarian individually for each animal, taking into account its age, breed, living conditions, and the epidemiological situation in the region. Key stages of vaccination include:")}</p>
                        <ul>
                            <li>
                                <strong>{t("Primary Vaccination")}</strong>: {t("Conducted at the age recommended for each vaccine. This usually includes several injections with intervals of a few weeks.")}
                            </li>
                            <li>
                                <strong>{t("Revaccination")}</strong>: {t("Performed after a certain period following primary vaccination to maintain immunity. The frequency of revaccination depends on the vaccine type and the manufacturer's recommendations.")}
                            </li>
                            <li>
                                <strong>{t("Annual Vaccination")}</strong>: {t("For certain diseases, such as rabies, annual revaccination is required throughout the animal's life.")}
                            </li>
                        </ul>

                        <h2>{t("Advice on Specific Diseases")}</h2>
                    </div>
                )}
                <h2>{t("Advice on specific diseases:")}</h2>
                <h1 className="chapter-header"
                    onClick={() => handleChapterToggle("Anthrax")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Anthrax")}{expandedChapter === "Anthrax" ? "▼" : "▶"}</h1>
                {expandedChapter === "Anthrax" && (
                    <div>
                        <h4>{t("Signs")}</h4>
                        <ul>
                            <li>
                                <strong>{t("Acute Form")}</strong>: {t("High fever, severe chills, muscle weakness, swelling, and hemorrhages in the skin and internal organs.")}
                            </li>
                            <li>
                                <strong>{t("Acute Septic Form")}</strong>: {t("Sudden death without visible signs of illness.")}
                            </li>
                            <li>
                                <strong>{t("Chronic Form")}</strong>: {t("Prolonged course with skin, bone, and joint lesions.")}
                            </li>
                        </ul>

                        <h4>{t("Transmission Methods")}</h4>
                        <ul>
                            <li>{t("Through contaminated soil, water, feed, or equipment.")}</li>
                            <li>{t("Direct contact with sick animals or their carcasses.")}</li>
                            <li>{t("Through insect bites.")}</li>
                        </ul>

                        <h4>{t("Treatment")}</h4>
                        <p>{t("Specific treatment is not available. Symptomatic therapy is performed.")}</p>

                        <h4>{t("Prevention")}</h4>
                        <ul>
                            <li>{t("Vaccination of animals.")}</li>
                            <li>{t("Compliance with sanitary and hygiene standards.")}</li>
                            <li>{t("Disinfection of premises and equipment.")}</li>
                        </ul>

                        <p>
                            <strong>{t("Important")}</strong>: {t("This is general information only. For accurate diagnosis and treatment, consult a veterinarian.")}
                        </p>

                        <p>
                            <strong>{t("Remember")}</strong>: {t("Preventing diseases is always more effective than treating them. Regular veterinary checkups, vaccinations, and adherence to sanitary and hygiene standards are the key to your animals' health.")}
                        </p>
                    </div>
                )}

                <h1 className="chapter-header"
                    onClick={() => handleChapterToggle("Swine")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Classical Swine Fever")}{expandedChapter === "Swine" ? "▼" : "▶"}</h1>
                {expandedChapter === "Swine" && (
                    <div>
                        <h3></h3>
                        <h4>{t("Signs")}</h4>
                        <ul>
                            <li>{t("High fever, loss of appetite, vomiting, diarrhea, constipation, coughing, and skin ulcers.")}</li>
                            <li>{t("Nervous disorders: Convulsions and paralysis.")}</li>
                        </ul>
                        <h4>{t("Transmission Methods")}</h4>
                        <ul>
                            <li>{t("Contact with sick animals or secretions from infected animals.")}</li>
                            <li>{t("Through contaminated feed, water, and equipment.")}</li>
                            <li>{t("Mechanical vectors (rodents, insects).")}</li>
                        </ul>
                        <h4>{t("Treatment")}</h4>
                        <p>{t("Specific treatment is unavailable. Symptomatic therapy is provided.")}</p>
                        <h4>{t("Prevention")}</h4>
                        <ul>
                            <li>{t("Vaccination of pigs.")}</li>
                            <li>{t("Maintaining sanitary and hygienic standards.")}</li>
                            <li>{t("Quarantine measures.")}</li>
                        </ul>
                        <p>
                            <strong>{t("Important")}</strong>: {t("This is general information. For an accurate diagnosis and treatment, consult a veterinarian.")}

                        </p>
                        <p>
                            <strong>{t("Remember")}</strong>: {t("Preventing diseases is always more effective than treating them. Regular veterinary checkups, vaccinations, and adherence to sanitar")}
                        </p>
                    </div>
                )}

                <h1 className=".feedback-title"
                    onClick={() => handleChapterToggle("Poultry")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Pasteurellosis in Poultry")}{expandedChapter === "Poultry" ? "▼" : "▶"}</h1>
                {expandedChapter === "Poultry" && (
                    <div>
                        <h4>{t("Signs")}</h4>
                        <ul>
                            <li>{t("Acute form: Sudden death without visible signs of illness.")}</li>
                            <li>{t("Chronic form: Decreased egg production, shortness of breath, wheezing, and nasal discharge.")}</li>
                        </ul>
                        <h4>{t("Transmission Methods")}</h4>
                        <ul>
                            <li>{t("Airborne transmission.")}</li>
                            <li>{t("Through contaminated feed, water, and equipment.")}</li>
                            <li>{t("Mechanical vectors (insects, rodents).")}</li>
                        </ul>
                        <h4>{t("Treatment")}</h4>
                        <p>{t("Antibiotic therapy.")}</p>
                        <h4>{t("Prevention")}</h4>
                        <ul>
                            <li>{t("Vaccination of poultry.")}</li>
                            <li>{t("Maintaining sanitary and hygienic standards.")}</li>
                        </ul>
                        <p>
                            <strong>{t("Important")}</strong>: {t("This is general information. For an accurate diagnosis and treatment, consult a veterinarian.")}
                        </p>
                        <p>
                            <strong>{t("Remember")}</strong>: {t("Preventing diseases is always more effective than treating them. Regular veterinary checkups, vaccinations, and adherence to sanitar")}
                        </p>
                    </div>
                )}

                <h1 className="chapter-header"
                    onClick={() => handleChapterToggle("Rabbit")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Rabbit Hemorrhagic Disease")}{expandedChapter === "Rabbit" ? "▼" : "▶"}</h1>
                {expandedChapter === "Rabbit" && (
                    <div>
                        <h4>{t("Signs")}</h4>
                        <ul>
                            <li>{t("Sudden death without visible signs of illness.")}</li>
                            <li>{t("In sick rabbits, hemorrhages on the skin and internal organs are observed.")}</li>
                        </ul>
                        <h4>{t("Transmission Methods")}</h4>
                        <ul>
                            <li>{t("Contact with sick animals or secretions from infected animals.")}</li>
                            <li>{t("Through contaminated feed, water, and equipment.")}</li>
                            <li>{t("Mechanical vectors (insects, rodents).")}</li>
                        </ul>
                        <h4>{t("Treatment")}</h4>
                        <p>{t("Specific treatment is unavailable.")}</p>
                        <h4>{t("Prevention")}</h4>
                        <ul>
                            <li>{t("Vaccination of rabbits.")}</li>
                            <li>{t("Maintaining sanitary and hygienic standards.")}</li>
                            <li>{t("Quarantine measures.")}</li>
                        </ul>
                        <p>
                            <strong>{t("Important")}</strong>: {t("This is general information. For an accurate diagnosis and treatment, consult a veterinarian.")}
                        </p>

                        <p>
                            <strong>{t("Remember")}</strong>: {t("Disease prevention is always more effective than treatment. Regular veterinary check-ups, vaccination, and maintaining sanitary standards are the keys to keeping your animals healthy.")}
                        </p>
                    </div>
                )}

                <h1 className="chapter-header"
                    onClick={() => handleChapterToggle("Myxomatosis")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Myxomatosis")}{expandedChapter === "Myxomatosis" ? "▼" : "▶"}</h1>
                {expandedChapter === "Myxomatosis" && (
                    <div>
                        <h4>{t("Signs")}</h4>
                        <ul>
                            <li>{t("Tumor formation on the skin and mucous membranes.")}</li>
                            <li>{t("Inflammation of the eyes, nasal discharge.")}</li>
                        </ul>
                        <h4>{t("Transmission Methods")}</h4>
                        <ul>
                            <li>{t("By insects (mosquitoes, midges).")}</li>
                            <li>{t("Contact with infected animals.")}</li>
                        </ul>
                        <h4>{t("Treatment")}</h4>
                        <p>{t("Symptomatic therapy.")}</p>
                        <h4>{t("Prevention")}</h4>
                        <ul>
                            <li>{t("Vaccination of rabbits.")}</li>
                            <li>{t("Control of insect vectors.")}</li>
                        </ul>
                        <p>
                            <strong>{t("Important")}</strong>: {t("This is general information. For an accurate diagnosis and treatment, consult a veterinarian.")}

                        </p>
                        <p>
                            <strong>{t("Remember")}</strong>: {t("Preventing diseases is always more effective than treating them. Regular veterinary checkups, vaccinations, and adherence to sanitar")}
                        </p>
                    </div>)}

                <h1 className="chapter-header"
                    onClick={() => handleChapterToggle("Newcastle")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Newcastle Disease in Poultry")}{expandedChapter === "Newcastle" ? "▼" : "▶"}</h1>
                {expandedChapter === "Newcastle" && (
                    <div>
                        <h4>{t("Signs")}</h4>
                        <ul>
                            <li>{t("Labored breathing, wheezing, nasal and eye discharge, reduced egg production, paralysis.")}</li>
                        </ul>
                        <h4>{t("Transmission Methods")}</h4>
                        <ul>
                            <li>{t("Airborne transmission.")}</li>
                            <li>{t("Contaminated feed, water, and equipment.")}</li>
                            <li>{t("Mechanical vectors (insects, rodents).")}</li>
                        </ul>
                        <h4>{t("Treatment")}</h4>
                        <p>{t("Symptomatic therapy.")}</p>
                        <h4>{t("Prevention")}</h4>
                        <ul>
                            <li>{t("Vaccination of poultry.")}</li>
                            <li>{t("Maintaining sanitary and hygienic standards.")}</li>
                        </ul>
                        <p>
                            <strong>{t("Important")}</strong>: {t("This is general information. For an accurate diagnosis and treatment, consult a veterinarian.")}
                        </p>
                        <p>
                            <strong>{t("Remember")}</strong>: {t("Preventing diseases is always more effective than treating them. Regular veterinary checkups, vaccinations, and adherence to sanitar")}
                        </p>
                    </div>)}

                <h2>{t("Use of Biofactory Products")}</h2>
                 <h1 className="chapter-header"
                    onClick={() => handleChapterToggle("Instructions")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Application Instructions:")}{expandedChapter === "Instructions" ? "▼" : "▶"}</h1>
                {expandedChapter === "Instructions" && (
                <section className="feedback-reviews">
                    <div className="feedback-pdf-list">

                        {pdfNames.map((pdfName, index) => {
                            return (
                                <a
                                    key={index}
                                    href={`/pdf/pdfAdvice/${pdfName}`}
                                    download={pdfName}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="Advice-pdf-link"
                                >
                                    {t("feedback.downloadPDF", {pdfName})}
                                </a>
                            );
                        })}
                    </div>
                </section>
    )}
                <h1 className="chapter-header"
                    onClick={() => handleChapterToggle("Transportation")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Storage and Transportation Rules")}{expandedChapter === "Transportation" ? "▼" : "▶"}</h1>
                {expandedChapter === "Transportation" && (
                    <div>
                        <h2></h2>
                        <h3>{t("Temperature Conditions")}</h3>
                        <p>{t("Most veterinary drugs need to be stored in a cool, dry place, away from direct sunlight. Exact temperature conditions are specified in the instructions for each drug.")}</p>

                        <h3>{t("Packaging")}</h3>
                        <p>{t("Drugs should be stored in their original packaging to prevent loss of effectiveness.")}</p>

                        <h3>{t("Transportation")}</h3>
                        <p>{t("When transporting drugs, protect them from mechanical damage, temperature fluctuations, and humidity.")}</p>
                    </div>)}


                <h1 className="chapter-header"
                    onClick={() => handleChapterToggle("Drugs")}
                    style={{cursor: "pointer", color: "#007BFF",fontSize: 24}}
                >{t("Safety Measures When Handling Drugs")}{expandedChapter === "Drugs" ? "▼" : "▶"}</h1>
                {expandedChapter === "Drugs" && (
                    <div>

                        <h3>{t("Protective Clothing")}</h3>
                        <p>{t("When working with drugs, protective clothing (coat, gloves, goggles) must be worn.")}</p>

                        <h3>{t("Hygiene")}</h3>
                        <p>{t("Before and after handling drugs, wash hands thoroughly with soap.")}</p>

                        <h3>{t("Ventilation")}</h3>
                        <p>{t("Ensure proper ventilation when working with drugs.")}</p>

                        <h3>{t("Avoid Contact")}</h3>
                        <p>{t("Avoid contact with the drug on skin, mucous membranes, and eyes.")}</p>

                        <h3>{t("Storage Away from Children")}</h3>
                        <p>{t("Store drugs in places that are inaccessible to children and pets.")}</p>
                    </div>

                )}


            </div>
        </div>
    )
        ;
};

export default Advice;
