import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/newPhoto/deratezzz.jpg";

function Deratez() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
const handleDownload = () => {
  const pdfPath = "/pdf/pdf_vkladki/ДЕРАТЕЗ.PDF"; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };

  return (
      <div className="descriptionPage">
        <div className="productDetails">
          <div className="title_image">
            <h1>{t("Deratez")}</h1>
            <img src={Cat} alt="Rodent Control Product" className="productImage"/>
          </div>
          <div className="productExtraInfo">
            {/*<p>*/}
            {/*  <strong>{t("Product Name")}:</strong> {t("Rodent Control Product 'Deratez'")}*/}
            {/*</p>*/}
            {/*<h3>{t("Composition Deratez")}</h3>*/}
            {/*<ul>*/}
            {/*  <li>{t("Cereal grains (wheat, rye, or barley): 100 g")}</li>*/}
            {/*  <li>{t("Salmonella S. enteritidis var. issatschenko: ≥ 100 million microbial cells/g")}</li>*/}
            {/*  <li>{t("Aerosil (A-300): 0.1 g")}</li>*/}
            {/*  <li>{t("Chalk: 230 mg")}</li>*/}
            {/*</ul>*/}

            {/*<h3>{t("Pharmaceutical Form")}</h3>*/}
            {/*<p>{t("Coarse-grained moist mass.")}</p>*/}

            {/*<h3>{t("Target Animals")}</h3>*/}
            {/*<p>{t("Rodents such as mice, rats, and voles.")}</p>*/}

            {/*<h3>{t("Immunobiological Properties")}</h3>*/}
            {/*<p>{t("None. Rodent death is observed within 5–14 days.")}</p>*/}

            {/*<h3>{t("Indications for Use")}</h3>*/}
            {/*<p>{t("The product is used to exterminate rodent populations in specific areas such as farms, premises, and agricultural lands.")}</p>*/}

            {/*<h3>{t("Precautions for Use")}</h3>*/}
            {/*<ul>*/}
            {/*  <li>{t("The product is non-toxic to productive animals.")}</li>*/}
            {/*  <li>{t("Avoid skin contact.")}</li>*/}
            {/*  <li>{t("Do not use in poultry houses where birds have not been vaccinated against salmonellosis.")}</li>*/}
            {/*  <li>{t("Do not use in rabbit farms, nutria farms, chinchilla farms, or vivariums.")}</li>*/}
            {/*  <li>{t("Do not apply in summer when the average daily temperature exceeds 20°C.")}</li>*/}
            {/*</ul>*/}

            {/*<h3>{t("Application and Dosage")}</h3>*/}
            {/*<p>*/}
            {/*  {t("For open areas: Apply 450 g per 500 m². Indoors: Place in rodent-infested areas. Ensure adequate quantity for consumption by rodents within a day. Replenish as needed after verifying consumption.")}*/}
            {/*</p>*/}
            {/*<p>{t("Consuming 1–2 grams of the product is lethal for rodents.")}</p>*/}

            {/*<h3>{t("Special Safety Measures")}</h3>*/}
            {/*<p>{t("Residues and unused product should be destroyed by burning.")}</p>*/}
            {/*<h3>{t("Shelf Life")}</h3>*/}
            {/*<ul>*/}
            {/*  <li>{t("12 months if packaged in hermetically sealed glass containers.")}</li>*/}
            {/*  <li>{t("6 months if packaged in polymer materials.")}</li>*/}
            {/*</ul>*/}

            {/*<h3>{t("Storage and Transportation Conditions")}</h3>*/}
            {/*<ul>*/}
            {/*  <li>{t("Store in a dark place at a temperature of 2–8°C, separate from animal feed.")}</li>*/}
            {/*  <li>{t("Storage must be inaccessible to children and domestic animals.")}</li>*/}
            {/*  <li>{t("Transport in factory packaging under the specified temperature conditions.")}</li>*/}
            {/*</ul>*/}

            {/*<h3>{t("Packaging")}</h3>*/}
            {/*<p>{t("Glass or plastic bottles with caps, vacuum-sealed polymer film.")}</p>*/}
            {/*<h4>{t("Product Characteristics")}</h4>*/}
            <p><strong>{t("Veterinary Product Name")}:</strong> {t("Rodent Control Product 'Deratez'")}</p>
            <ul>
              <p><strong>{t("Deratez, 10 kg (Vacuum-sealed package)")}</strong></p>
              <p><strong>{t("Price per unit")}</strong>: 1560.00 {t("UAH")}</p>
              <p><strong>{t("Minimum order")}</strong>: 1 {t("unit")}</p>
              <p><strong>{t("Availability")}</strong>: {t("Out of stock")}</p>

              <p><strong>{t("Deratez, 1 kg (Vacuum-sealed package)")}</strong></p>
              <p><strong>{t("Price per unit")}</strong>: 159.00 {t("UAH")}</p>
              <p><strong>{t("Minimum order")}</strong>: 1 {t("unit")}</p>
              <p><strong>{t("Availability")}</strong>: {t("In stock")}</p>

              <p><strong>{t("Deratez, 0.4 kg (Vacuum-sealed package)")}</strong></p>
              <p><strong>{t("Price per unit")}</strong>: 76.80 {t("UAH")}</p>
              <p><strong>{t("Minimum order")}</strong>: 1 {t("unit")}</p>
              <p><strong>{t("Availability")}</strong>: {t("In stock")}</p>

              <p><strong>{t("Deratez, 0.8 kg (Jar)")}</strong></p>
              <p><strong>{t("Price per unit")}</strong>: 153.60 {t("UAH")}</p>
              <p><strong>{t("Minimum order")}</strong>: 1 {t("unit")}</p>
              <p><strong>{t("Availability")}</strong>: {t("Out of stock")}</p>

              <p><strong>{t("Deratez, 0.350 kg (Jar)")}</strong></p>
              <p><strong>{t("Price per unit")}</strong>: 67.20 {t("UAH")}</p>
              <p><strong>{t("Minimum order")}</strong>: 1 {t("unit")}</p>
              <p><strong>{t("Availability")}</strong>: {t("Out of stock")}</p>
              <li>
                <strong>{t("Qualitative and Quantitative Composition")}:</strong>
                <ul>
                  <li>{t("Cereal grains (wheat, rye, or barley): 100 g")}</li>
                  <li>{t("Salmonella S. enteritidis var. issatschenko: ≥ 100 million microbial cells/g")}</li>
                  <li>{t("Aerosil (A-300): 0.1 g")}</li>
                  <li>{t("Chalk: 230 mg")}</li>
                </ul>
              </li>
              <li>
                <strong>{t("Pharmaceutical Form")}:</strong> {t("Coarse-grained moist mass.")}
              </li>
              <li>
                <strong>{t("Immunobiological Properties")}:</strong> {t("None. Rodent death is observed within 5–14 days.")}
              </li>
              <li>
                <strong>{t("Clinical Characteristics")}:</strong>
                <ul>
                  <li>{t("Target Animals: Rodents such as mice, rats, and voles.")}</li>
                  <li>{t("Indications: For the destruction of rodent populations in farms, premises, and agricultural lands.")}</li>
                  <li>{t("Contraindications: None.")}</li>
                  <li>{t("Side Effects: None.")}</li>
                  <li>{t("Precautions: Avoid skin contact with the product.")}</li>
                  <li>{t("Use During Pregnancy/Lactation: No restrictions.")}</li>
                  <li>{t("Interactions: Not known.")}</li>
                </ul>
              </li>
              <li>
                <strong>{t("Dosage")}:</strong>
                <ul>
                  <li>{t("For open areas: 450 g per 500 m².")}</li>
                  <li>{t("Indoors: Place in special packaging at rodent-infested areas.")}</li>
                  <li>{t("Rodents consuming 1–2 grams will die.")}</li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>{t("Overdose")}:</strong> {t("In case of accidental consumption by non-target animals, administer antimicrobial agents and symptomatic treatment.")}
            </p>
            <p>
              <h3>{t("Special Precautions")}</h3>
              <p>{t("Do not use for rodent control in rabbit farms or farms raising nutria, chinchillas, etc., or in vivariums.")}</p>
              <p>{t("Do not apply in the summer when the average daily temperature exceeds 20°C.")}</p>

              <h3>{t("Withdrawal Period (Residue Time)")}</h3>
              <p>{t("None. Salmonella actively multiplies in the rodent's body and causes its death.")}</p>

              <h3>{t("Special Precautions for Personnel Handling the Product")}</h3>
              <p>{t("If the product comes into contact with the skin or mucous membranes of a person, wash hands with soap and rinse mucous membranes with sanitizing solutions, then seek medical attention.")}</p>

              <h3>{t("Pharmaceutical Particulars")}</h3>

              <h4>{t("Main Forms of Incompatibility")}</h4>
              <p>{t("Unknown.")}</p>

              <h4>{t("Shelf Life")}</h4>
              <ul>
                <li>{t("6 months if packaged in polymer materials.")}</li>
                <li>{t("12 months if packaged in hermetically sealed glass containers.")}</li>
              </ul>

              <h4>{t("Special Storage Precautions")}</h4>
              <ul>
                <li>{t("Store in a dark place at a temperature of 2–8°C, separate from animal feed.")}</li>
                <li>{t("Storage must be inaccessible to children and pets.")}</li>
                <li>{t("Shelf life after opening (sampling): 3 days.")}</li>
              </ul>

              <h4>{t("Nature and Composition of the Primary Packaging Container")}</h4>
              <p>{t("Glass or polymer bottles with caps. Vacuum packaging in polymer film.")}</p>

              <h4>{t("Name and Location of the Holder of the Registration Certificate")}</h4>
              <p>{t("State Scientific-Control Institute of Biotechnology and Microorganism Strains. Ukraine, 03151, Kyiv, Donetska St., 30.")}</p>

              <h4>{t("Name and Location of the Manufacturers")}</h4>
              <ul>
                <li>{t("Kherson State Enterprise - Biological Factory, Ukraine, 73011, Kherson, Admiral Makarov St., 9.")}</li>
                <li>{t("State Enterprise 'Sumy Biological Factory,' 40021, Sumy, Hamalia St., 25.")}</li>
              </ul>

              <h4>{t("Special Safety Measures for Handling Unused Product")}</h4>
              <p>{t("Unused product and residues must be destroyed by burning or autoclaving.")}</p>

              <h3>{t("Additional Information")}</h3>
              <p>{t("If the product does not meet the requirements of the leaflet or complications arise, the use of this batch should be immediately stopped, and the State Scientific-Control Institute of Biotechnology and Microorganism Strains (SSCIB) and the supplier (manufacturer) must be notified. Simultaneously, three unopened vials of this batch should be sent to SSCIB at 03151, Kyiv, Donetska St., 30, SSCIB.")}</p>
            </p>
            <div className="productDownloadRegistrationCert">
              <button onClick={handleDownload} className="productDownloadRegistrationCert">
                {t("Download Product Registration")}
              </button>
            </div>
            <div className="productDownloadRegistrationCert">
              <Link to="/contact" className="productDownloadRegistrationCert">
                {t("Check availability")}
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar">
          <h2>{t("Search the Product")}</h2>
          <label>
            {t("Animal")}:
            <select
                value={selectedAnimal}
                onChange={(e) => setSelectedAnimal(e.target.value)}
            >
              <option value="all">{t("another")}</option>

              <option value="pig">{t("Pig")}</option>
              <option value="cattle">{t("Cattle")}</option>

              <option value="poultry">{t("Poultry")}</option>


              <option value="rabbit">{t("Rabbit")}</option>

              <option value="rodent">{t("Rodents")}</option>

            </select>
          </label>
          <label>
            {t("Category")}:
            <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="all">{t("All")}</option>
              <option value="vaccines">{t("Vaccines")}</option>
              <option value="diagnostics">{t("Diagnostics")}</option>
              <option value="rodenticides">{t("Rodenticides")}</option>
              <option value="test-kits">{t("Test Kits")}</option>
              <option
                  value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
              <option value="experimental-drugs">{t("Experimental Drugs")}</option>

            </select>
          </label>
          <label>
            {t("Product Name")}:
            <input
                type="text"
                placeholder={t("Enter product name")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </label>
          <label>
            {t("Active Ingredient")}:
            <input
                type="text"
                placeholder={t("Enter active ingredient")}
                value={activeIngredient}
                onChange={(e) => setActiveIngredient(e.target.value)}
            />
          </label>
          <label>
            {t("Pharmaceutical Form")}:
            <input
                type="text"
                placeholder={t("Enter pharmaceutical form")}
                value={pharmaceuticalForm}
                onChange={(e) => setPharmaceuticalForm(e.target.value)}
            />
          </label>
          <button onClick={handleReset} style={{marginBottom: "10px"}}>
            {t("Reset Search")}
          </button>
          <button onClick={handleSearchClick}>{t("Search")}</button>
        </div>
      </div>
  );
}

export default Deratez;
