import React, { useState, useEffect } from "react";
import { MenuList } from "../helper/MenuList";
import MenuItem from "./MenuItem";
import { useLocation } from "react-router-dom";
import "../components/styles/Menu.css";
import { useTranslation } from 'react-i18next';

function Menu() {
  const location = useLocation();

  const initialSearch = location.state?.searchQuery || "";
  const initialCategory = location.state?.selectedCategory || "all";
  const initialAnimal = location.state?.selectedAnimal || "all";

  const [searchTerm, setSearchTerm] = useState(initialSearch);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [selectedAnimal, setSelectedAnimal] = useState(initialAnimal);
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [animalFilter, setAnimalFilter] = useState("all");
  const [activeIngredientQuery, setActiveIngredientQuery] = useState("");
  const [pharmaceuticalFormQuery, setPharmaceuticalFormQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  useEffect(() => {
    setSearchQuery(initialSearch);
    setCategoryFilter(initialCategory);
    setAnimalFilter(initialAnimal);
    setActiveIngredientQuery(location.state?.activeIngredient || "");
    setPharmaceuticalFormQuery(location.state?.pharmaceuticalForm || "");
  }, [initialSearch, initialCategory, initialAnimal, location.state]);

  const handleSearchClick = () => {
    setSearchQuery(searchTerm);
    setCategoryFilter(selectedCategory);
    setAnimalFilter(selectedAnimal);
    setActiveIngredientQuery(activeIngredient);
    setPharmaceuticalFormQuery(pharmaceuticalForm);
    setCurrentPage(1);
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
    setSearchQuery("");
    setCategoryFilter("all");
    setAnimalFilter("all");
    setActiveIngredientQuery("");
    setPharmaceuticalFormQuery("");
    setCurrentPage(1);
  };

  const filteredMenuList = MenuList.filter((menuItem) => {
    return (
      menuItem.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (categoryFilter === "all" || menuItem.category === categoryFilter) &&
      (animalFilter === "all" || menuItem.animal === animalFilter) &&
      (activeIngredientQuery === "" || menuItem.activeIngredient.toLowerCase().includes(activeIngredientQuery.toLowerCase())) &&
      (pharmaceuticalFormQuery === "" || menuItem.pharmaceuticalForm.toLowerCase().includes(pharmaceuticalFormQuery.toLowerCase()))
    );
  });

  const totalPages = Math.ceil(filteredMenuList.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredMenuList.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const { t } = useTranslation();

  return (

    <div className="menuPage">
      <div className="menuContent">
        <h1 className="menuTitle">{t("Our Menu")}</h1>
        <div className="menuList">
          {currentItems.length > 0 ? (
            currentItems.map((menuItem, key) => (
              <MenuItem
                key={key}
                id={menuItem.id}
                image={menuItem.image}
                name={t(menuItem.nameText)} // Translate the name
                category={t(menuItem.categoryText)} // Translate the category
              />
            ))
          ) : (
            <div className="notFound">
              <p>{t("Sorry, but nothing matches your search terms. Please try again with other keywords.")}</p>
            </div>
          )}
        </div>
        {filteredMenuList.length > itemsPerPage && (
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="sidebar">
        <h2>{t("Search the Product")}</h2>
        <label>
          {t("Animal")}:
          <select
              value={selectedAnimal}
              onChange={(e) => setSelectedAnimal(e.target.value)}
          >
            <option value="all">{t("another")}</option>

            <option value="pig">{t("Pig")}</option>
            <option value="cattle">{t("Cattle")}</option>

            <option value="poultry">{t("Poultry")}</option>


            <option value="rabbit">{t("Rabbit")}</option>

            <option value="rodent">{t("Rodents")}</option>

          </select>
        </label>
        <label>
          {t("Category")}:
          <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="all">{t("All")}</option>
            <option value="vaccines">{t("Vaccines")}</option>
            <option value="diagnostics">{t("Diagnostics")}</option>
            <option value="rodenticides">{t("Rodenticides")}</option>
            <option value="test-kits">{t("Test Kits")}</option>
            <option
                value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
            <option value="experimental-drugs">{t("Experimental Drugs")}</option>

          </select>
        </label>
        <label>
          {t("Product Name")}:
          <input
              type="text"
              placeholder={t("Enter product name")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
          />
        </label>
        <label>
          {t("Active Ingredient")}:
          <input
              type="text"
              placeholder={t("Enter active ingredient")}
              value={activeIngredient}
              onChange={(e) => setActiveIngredient(e.target.value)}
          />
        </label>
        <label>
          {t("Pharmaceutical Form")}:
          <input
            type="text"
            placeholder={t("Enter pharmaceutical form")}
            value={pharmaceuticalForm}
            onChange={(e) => setPharmaceuticalForm(e.target.value)}
          />
        </label>
        <button onClick={handleReset} style={{ marginBottom: "10px" }}>
          {t("Reset Search")}
        </button>
        <button onClick={handleSearchClick}>{t("Search")}</button>
      </div>
    </div>
  );
}

export default Menu;
