import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/newPhoto/mixovak.jpg";

function Mixovak() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };
const handleDownload = () => {
  const pdfPath = ""; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };

  return (
      <div className="descriptionPage">
        <div className="productDetails">
          <div className="title_image">
            <h1>{t("Mixovac")}</h1>
            <img src={Cat} alt="Mixovac Vaccine" className="productImage"/>
          </div>
          <div className="productExtraInfo">
            <p>
              <strong>{t("Product Name")}:</strong> {t("Mixovac - Vaccine Against Myxomatosis in Rabbits")}
            </p>
            <p><strong>{t("Price per vial (10 doses)")}</strong>: 90.00 {t("UAH")}</p>
            <p><strong>{t("Minimum order")}</strong>: 10 {t("vials")}</p>
            <p><strong>{t("Availability")}</strong>: {t("In stock")}</p>

            <h3>{t("Composition")}</h3>
            <ul>
              <li>{t("One dose contains an attenuated myxoma virus strain 'V-82' with activity not less than 104.0 ID50/cm3")}</li>
              <li>{t("Protective medium containing:")}</li>
              <ul>
                <li>{t("Demineralized water: 0.95 cm³")}</li>
                <li>{t("Potassium dihydrogen phosphate (KH2PO4): 0.528 mg")}</li>
                <li>{t("Sodium hydrogen phosphate (NaH2PO4): 2.19 mg")}</li>
                <li>{t("Glycerin: 0.05 cm³")}</li>
              </ul>
            </ul>
            <h3>{t("Pharmaceutical Form")}</h3>
            <p>{t("The vaccine is a lyophilized product.")}</p>
            <p>{t("The solvent is a sterile, clear, colorless liquid.")}</p>

            <h3>{t("Immunobiological Properties")}</h3>
            <p>{t("Immunity develops in rabbits on the 9th day and lasts for 9 months.")}</p>
            <p>{t("The vaccine does not possess therapeutic properties.")}</p>

            <h3>{t("Animal Species")}</h3>
            <p>{t("Rabbits.")}</p>

            <h3>{t("Indications for Use")}</h3>
            <p>
              {t(
                  "The vaccine is used for the prophylactic immunization of rabbits against myxomatosis in favorable, threatened, and unfavorable areas. In favorable and threatened areas, adult rabbits are immunized once. In unfavorable areas, clinically healthy rabbits and kits aged 28 days or older are vaccinated. Young rabbits are vaccinated from 1.5 months old and revaccinated after 3 months."
              )}
            </p>

            <h3>{t("Contraindications")}</h3>
            <p>
              {t("Vaccination is prohibited for rabbits showing clinical signs of disease or when acute infectious diseases are present in the herd. In case of vaccination during the incubation period, death may occur within the first four days.")}
            </p>
            <p>
              {t("In myxomatosis-affected areas, sick rabbits are culled and burned with their skin. Housing areas, passageways, and equipment are disinfected.")}
            </p>
            <p>
              {t("Vaccinate only clinically healthy rabbits in areas where no cases of myxomatosis have occurred. It is recommended to vaccinate in spring before the appearance of main carriers like mosquitoes.")}
            </p>

            <h3>{t("Precautions for Use")}</h3>
            <p>{t("Exhausted rabbits or those with high body temperatures are not vaccinated.")}</p>
            <p>
              {t(
                  "In farms where vaccination against viral hemorrhagic disease is conducted, the myxomatosis vaccine is administered 14 days after the hemorrhagic disease vaccination."
              )}
            </p>

            <h3>{t("Interaction with Other Products")}</h3>
            <p>{t("Concurrent administration with the rabbit hemorrhagic disease vaccine is possible. Vaccines must not be mixed in one dose.")}</p>

            <h3>{t("Special Instructions for Pregnant or Lactating Rabbits")}</h3>
            <p>{t("Pregnant does can be vaccinated at any stage of pregnancy.")}</p>

            <h3>{t("Method of Administration and Dosage")}</h3>
            <p>{t("The vaccine is dissolved in a solvent or saline solution.")}</p>

            {t("For intradermal use, dissolve vaccine in vials containing 10, 20, 25, 30, or 50 doses in 0.15, 0.3, 0.375, 0.45, or 0.75 cm³ solvent, respectively. Inject using a two-needle injector into the inner side of the rabbit's ear.")}

            {t("For intramuscular use, dissolve vaccine in vials containing 10, 20, 25, 30, or 50 doses in 10, 20, 25, 30, or 50 cm³ solvent, respectively, and inject 1 cm³ into the thigh area.")}

            <h3>{t("Special Precautions for Individuals and Service Personnel Handling the Vaccine")}</h3>
            <p>{t("Before vaccination, injector needles are pre-heated and cooled, and needles and syringes are sterilized by boiling. Hot needles must not be used for vaccination.")}</p>


            <h3>{t("Special Safety Measures for Handling Unused Vaccine, Disposal, and Decontamination Methods")}</h3>
            <p>{t("The vaccine is decontaminated by boiling for 30 minutes or autoclaving at 132°C for 30 minutes.")}</p>


            <h3>{t("Side Effects")}</h3>
            <p>{t("Vaccinated animals may experience reduced appetite for 1-2 days.")}</p>

            <h3>{t("Withdrawal Period")}</h3>
            <p>{t("Zero days.")}</p>

            <h3>{t("Storage and Transportation")}</h3>
            <p>{t("Store in a dry, dark place at 2-8°C. Solvent should be stored between 2°C and 25°C. Transport under cold chain conditions. Keep out of reach of children.")}</p>

            <h3>{t("Shelf Life")}</h3>
            <p>{t("Vaccine: 12 months. Solvent: 12 months. After dissolution: 4 hours.")}</p>

            <h3>{t("Packaging")}</h3>
            <p>{t("Glass vials containing 10 cm³ with 10, 20, 25, 30, or 50 doses.")}</p>

            <h3>{t("Manufacturer")}</h3>
            <p>{t("State Enterprise 'Sumy Biological Factory,' Ukraine, Sumy, Likhvinska Stinka St. 25, Tel/Fax: (0542) 617-008.")}</p>

            <h3>{t("Additional Information")}</h3>
            <p>{t("If the product does not meet requirements or complications arise, stop using the batch immediately and inform the State Scientific Control Institute of Biotechnology and Microorganism Strains (SSCIB) and the supplier.")}</p>
            {/*<div className="productDownloadRegistrationCert">*/}
            {/*  <button onClick={handleDownload} className="productDownloadRegistrationCert">*/}
            {/*    {t("Download Product Registration")}*/}
            {/*  </button>*/}
            {/*</div>*/}
            <div className="productDownloadRegistrationCert">
              <Link to="/contact" className="productDownloadRegistrationCert">
                {t("Check availability")}
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar">
          <h2>{t("Search the Product")}</h2>
          <label>
            {t("Animal")}:
            <select
                value={selectedAnimal}
                onChange={(e) => setSelectedAnimal(e.target.value)}
            >
              <option value="all">{t("another")}</option>

              <option value="pig">{t("Pig")}</option>
              <option value="cattle">{t("Cattle")}</option>

              <option value="poultry">{t("Poultry")}</option>


              <option value="rabbit">{t("Rabbit")}</option>

              <option value="rodent">{t("Rodents")}</option>

            </select>
          </label>
          <label>
            {t("Category")}:
            <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="all">{t("All")}</option>
              <option value="vaccines">{t("Vaccines")}</option>
              <option value="diagnostics">{t("Diagnostics")}</option>
              <option value="rodenticides">{t("Rodenticides")}</option>
              <option value="test-kits">{t("Test Kits")}</option>
              <option
                  value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
              <option value="experimental-drugs">{t("Experimental Drugs")}</option>

            </select>
          </label>
          <label>
            {t("Product Name")}:
            <input
                type="text"
                placeholder={t("Enter product name")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </label>
          <label>
            {t("Active Ingredient")}:
            <input
                type="text"
                placeholder={t("Enter active ingredient")}
                value={activeIngredient}
                onChange={(e) => setActiveIngredient(e.target.value)}
            />
          </label>
          <label>
            {t("Pharmaceutical Form")}:
            <input
                type="text"
                placeholder={t("Enter pharmaceutical form")}
                value={pharmaceuticalForm}
                onChange={(e) => setPharmaceuticalForm(e.target.value)}
            />
          </label>
          <button onClick={handleReset} style={{marginBottom: "10px"}}>
            {t("Reset Search")}
          </button>
          <button onClick={handleSearchClick}>{t("Search")}</button>
        </div>
      </div>
  );
}

export default Mixovak;
