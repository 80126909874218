import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReorderIcon from "@mui/icons-material/Reorder";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logo from "../assets/ЛОГО_NEW.png";
import "../components/styles/NavbarMobile.css";
import { useTranslation } from "react-i18next";

function NavbarMobile() {
    const [openLinks, setOpenLinks] = useState(false); // For the main menu toggle
    const [dropdownOpen, setDropdownOpen] = useState({}); // Tracks which dropdowns are open
    const dropdownRefs = useRef({}); // To store references to dropdown menus
    const { t, i18n } = useTranslation(); // Access translation and i18n instance

    // Toggles the navbar for smaller screens
    const toggleNavbar = () => {
        setOpenLinks(!openLinks);
    };

    // Toggles a specific dropdown menu
    const toggleDropdown = (menu) => {
        setDropdownOpen((prevState) => ({
            ...prevState,
            [menu]: !prevState[menu],
        }));
    };

    // Changes the language
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setDropdownOpen((prevState) => ({
            ...prevState,
            language: false, // Close the language dropdown after selecting a language
        }));
    };

    // Closes all dropdowns when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            let clickedOutside = true;
            Object.values(dropdownRefs.current).forEach((ref) => {
                if (ref && ref.contains(event.target)) {
                    clickedOutside = false;
                }
            });

            if (clickedOutside) {
                setDropdownOpen({});
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="navbar-mobile">
            {/* Left Side */}
            <div className="leftSide">
                <Link to="/" onClick={() => setOpenLinks(false)}>
                    <img src={Logo} alt="logo" />
                </Link>
            </div>

            {/* Hamburger Icon */}
            <button className="reorder" onClick={toggleNavbar}>
                <ReorderIcon />
            </button>

            {/* Right Side */}
            <div className={`rightSide ${openLinks ? "active" : ""}`}>
                <Link to="/" onClick={() => setOpenLinks(false)}>
                    {t("Home")}
                </Link>

                {/* About Dropdown */}
                <div
                    className="dropdown"
                    ref={(el) => (dropdownRefs.current["about"] = el)}
                >
                    <button onClick={() => toggleDropdown("about")}>
                        {t("About Us")} <ArrowDropDownIcon sx={{ color: "#F43940" }} />
                    </button>
                    {dropdownOpen["about"] && (
                        <ul className="submenu show">
                            <li>
                                <Link to="/about/history" onClick={() => setOpenLinks(false)}>
                                    {t("General Information")}
                                </Link>
                            </li>

                            <li>
                                <Link to="/about/certificates" onClick={() => setOpenLinks(false)}>
                                    {t("Product quality")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/about/vacancies" onClick={() => setOpenLinks(false)}>
                                    {t("Vacancies")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/about/news" onClick={() => setOpenLinks(false)}>
                                    {t("News")}
                                </Link>
                            </li>
                        </ul>
                    )}
                </div>

                {/* Products Link */}
                <Link to="/menu" onClick={() => setOpenLinks(false)}>
                    {t("Products")}
                </Link>

                {/* Contacts Dropdown */}
                <div
                    className="dropdown"
                    ref={(el) => (dropdownRefs.current["contacts"] = el)}
                >
                    <button onClick={() => toggleDropdown("contacts")}>
                        {t("Contacts")} <ArrowDropDownIcon sx={{ color: "#F43940" }} />
                    </button>
                    {dropdownOpen["contacts"] && (
                        <ul className="submenu show">
                            <li>
                                <Link to="/contacts/addresses" onClick={() => setOpenLinks(false)}>
                                    {t("Addresses")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/contacts/map" onClick={() => setOpenLinks(false)}>
                                    {t("Directions")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact" onClick={() => setOpenLinks(false)}>
                                    {t("Feedback Form")}
                                </Link>
                            </li>
                        </ul>
                    )}
                </div>

                <Link to="/payment" onClick={() => setOpenLinks(false)}>
                    {t("Payment & Delivery")}
                </Link>
                <Link to="/exchange" onClick={() => setOpenLinks(false)}>
                    {t("Exchange & Returns")}
                </Link>

                {/* Recommendations Dropdown */}
                <div
                    className="dropdown"
                    ref={(el) => (dropdownRefs.current["recommendations"] = el)}
                >
                    <button onClick={() => toggleDropdown("recommendations")}>
                        {t("Recommendations")} <ArrowDropDownIcon sx={{ color: "#F43940" }} />
                    </button>
                    {dropdownOpen["recommendations"] && (
                        <ul className="submenu show">
                            <li>
                                <Link to="/recommendations/collaboration" onClick={() => setOpenLinks(false)}>
                                    {t("Collaboration")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/recommendations/reviews" onClick={() => setOpenLinks(false)}>
                                    {t("Reviews")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/recommendations/tips" onClick={() => setOpenLinks(false)}>
                                    {t("Advice & Tips")}
                                </Link>
                            </li>
                        </ul>
                    )}
                </div>

                {/* Language Dropdown */}
                <div
                    className="dropdown"
                    ref={(el) => (dropdownRefs.current["language"] = el)}
                >
                    <button onClick={() => toggleDropdown("language")}>
                        {i18n.language.toUpperCase()}{" "}
                        <ArrowDropDownIcon sx={{ color: "#F43940" }} />
                    </button>
                    {dropdownOpen["language"] && (
                        <ul className="submenu show">
                            <li>
                                <button onClick={() => changeLanguage("en")}>EN</button>
                            </li>
                            <li>
                                <button onClick={() => changeLanguage("ukr")}>UKR</button>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

export default NavbarMobile;
