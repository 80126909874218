import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/newPhoto/bird_tuberkulin.jpg";

function TuberkulinPure() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };
const handleDownload = () => {
  const pdfPath = "/pdf/pdf_vkladki/ЛИСТІВКА-ВКЛАДКА_ТУБ_ПТИЦІ.PDF"; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  return (
      <div className="descriptionPage">
        <div className="productDetails">
          <div className="title_image">
            <h1>{t("Purified Tuberculin (PPD) for Poultry")}</h1>
            <img src={Cat} alt="Purified Tuberculin" className="productImage"/>
          </div>
          <div className="productExtraInfo">
            <p><strong>{t("Full Name:")}</strong> {t("Purified Tuberculin (PPD) for poultry in standard solution.")}</p>
            <p><strong>{t("Price per vial (50 doses)")}</strong>: 349.50 {t("UAH")}</p>
            <p><strong>{t("Minimum order")}</strong>: 1 {t("vial")}</p>
            <p><strong>{t("Availability")}</strong>: {t("In stock")}</p>
            <h3>{t("Composition")}</h3>
            <p>
              {t(
                  "Cultural filtrate of the production strain M. avium IEKVM UAAN or M. avium ZSP-93, containing: sodium chloride – (0.85±0.05)%, glycerin – (10.0±1.0)%, phenol – (0.3±0.1)%, total protein – (0.8±0.2) mg/cm³, pH – (6.5-7.2)."
              )}
            </p>

            <h3>{t("Pharmaceutical Form")}</h3>
            <p>{t("Liquid.")}</p>

            <h3>{t("Biological Properties")}</h3>
            <p>
              {t(
                  "After intradermal administration, animals infected with tuberculosis or paratuberculosis develop a localized delayed-type hypersensitivity reaction."
              )}
            </p>

            <h3>{t("Target Animals")}</h3>
            <p>
              {t(
                  "Poultry (chickens, turkeys, geese, ducks, and other domestic and ornamental birds), pigs, cattle, sheep, and goats."
              )}
            </p>

            <h3>{t("Indications for Use")}</h3>
            <p>
              {t(
                  "For in vivo allergic diagnosis of tuberculosis in poultry and pigs, and paratuberculosis in cattle and small ruminants."
              )}
            </p>

            <h3>{t("Contraindications")}</h3>
            <p>
              {t(
                  "Tuberculin testing is not permitted in sick or emaciated animals, lactating mammals within 30 days postpartum, or less than 21 days after vaccination against infectious diseases or deworming."
              )}
            </p>

            <h3>{t("Precautions for Use")}</h3>
            <p>
              {t(
                  "Do not use if the vial’s seal is broken, if the solution contains mechanical impurities, or if there is a change in color."
              )}
            </p>

            <h3>{t("Interactions with Other Products")}</h3>
            <p>{t("None established.")}</p>

            <h3>{t("Special Instructions During Pregnancy, Lactation, and Egg Production")}</h3>
            <p>
              {t(
                  "Testing is not allowed for sows, cows, sheep, or goats within 30 days postpartum."
              )}
            </p>

            <h3>{t("Nature of the Reaction")}</h3>
            <p>{t("Delayed-type hypersensitivity.")}</p>

            <h3>{t("Method of Application and Dosages")}</h3>
            <p>
              {t(
                  "For tuberculosis: test poultry from 6 months old, pigs from 2 months old, using purified tuberculin (PPD) for poultry and mammals simultaneously."
              )}
            </p>
            <p>
              {t(
                  "For paratuberculosis: test cattle from 6 months old, sheep and goats from 3 months old."
              )}
            </p>


            <h3>{t("evaluation_and_interpretation.title")}</h3>
            <p>{t("evaluation_and_interpretation.description")}</p>
            <ul>
              <li>{t("evaluation_and_interpretation.poultry", {hours: "30–36"})}</li>
              <li>{t("evaluation_and_interpretation.pigs_sheep_goats", {hours: "48"})}</li>
              <li>{t("evaluation_and_interpretation.cattle", {hours: "72"})}</li>
            </ul>
            <p>{t("evaluation_and_interpretation.positive_reaction_title")}</p>
            <ul>
              <li>{t("evaluation_and_interpretation.poultry_pigs_reaction")}</li>
              <li>{t("evaluation_and_interpretation.cattle_reaction", {thickness: "4.0 mm"})}</li>
              <li>{t("evaluation_and_interpretation.sheep_goats_reaction")}</li>
            </ul>


            <h3>{t("Non-specific Reactions")}</h3>
            <p>{t("May include para-allergic and pseudo-allergic reactions.")}</p>

            <h3>{t("Overdose")}</h3>
            <p>{t("Not reported.")}</p>

            <h3>{t("Special Precautions for Personnel")}</h3>
            <p>
              {t(
                  "Personnel with increased allergic sensitivity should not handle the product."
              )}
            </p>

            <h3>{t("Disposal of Unused Product")}</h3>
            <p>{t("Decontaminate by boiling for 30 minutes.")}</p>

            <h3>{t("Shelf Life")}</h3>
            <p>{t("2 years from the manufacturing date. Use the vial on the day it is opened.")}</p>

            <h3>{t("Storage and Transportation Conditions")}</h3>
            <p>{t("Store in the manufacturer’s packaging in a closed, dry area at 4–8°C.")}</p>

            <h3>{t("Packaging")}</h3>
            <p>
              {t(
                  "Glass vials containing 10.0 cm³ (2.0 cm³, 5.0 cm³, or 10.0 cm³ of the product per vial, equivalent to 20, 50, or 100 doses per vial)."
              )}
            </p>

            <h3>{t("Name and Location of Registration Certificate Holder")}</h3>
            <p>
              {t(
                  "National Scientific Center 'Institute of Experimental and Clinical Veterinary Medicine', Ukraine, 61023, Kharkiv, 83 Hryhoriy Skovoroda Street, Tel./Fax: (057) 707-20-44 / 704-10-90."
              )}
            </p>

            <h3>{t("Manufacturer")}</h3>
            <p>
              {t(
                  "State Enterprise 'Sumy Biological Factory', 40000, Sumy, 25 Lykhvynska Stinka Street, Ukraine, Tel./Fax: (0542) 77-00-60."
              )}
            </p>

            <h3>{t("Additional Information")}</h3>
            <p>
              {t(
                  "If the product does not meet the requirements of the insert or complications occur, immediately stop using this batch and notify the State Scientific Control Institute of Biotechnology and Microorganism Strains (DNKIBSHM) and the supplier (manufacturer). Send three unopened vials from this batch to DNKIBSHM according to 'Guidelines on Filing Complaints About Biological Products for Veterinary Medicine Use' dated 03.06.98 No. 2, to the address: 03151, Kyiv, 30 Donetska Street, DNKIBSHM."
              )}
            </p>
            <div className="productDownloadRegistrationCert">
              <button onClick={handleDownload} className="productDownloadRegistrationCert">
                {t("Download Product Registration")}
              </button>
            </div>
            <div className="productDownloadRegistrationCert">
              <Link to="/contact" className="productDownloadRegistrationCert">
                {t("Check availability")}
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar">
          <h2>{t("Search the Product")}</h2>
          <label>
            {t("Animal")}:
            <select
                value={selectedAnimal}
                onChange={(e) => setSelectedAnimal(e.target.value)}
            >
              <option value="all">{t("another")}</option>

              <option value="pig">{t("Pig")}</option>
              <option value="cattle">{t("Cattle")}</option>

              <option value="poultry">{t("Poultry")}</option>


              <option value="rabbit">{t("Rabbit")}</option>

              <option value="rodent">{t("Rodents")}</option>

            </select>
          </label>
          <label>
            {t("Category")}:
            <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="all">{t("All")}</option>
              <option value="vaccines">{t("Vaccines")}</option>
              <option value="diagnostics">{t("Diagnostics")}</option>
              <option value="rodenticides">{t("Rodenticides")}</option>
              <option value="test-kits">{t("Test Kits")}</option>
              <option
                  value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
              <option value="experimental-drugs">{t("Experimental Drugs")}</option>

            </select>
          </label>
          <label>
            {t("Product Name")}:
            <input
                type="text"
                placeholder={t("Enter product name")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </label>
          <label>
            {t("Active Ingredient")}:
            <input
                type="text"
                placeholder={t("Enter active ingredient")}
                value={activeIngredient}
                onChange={(e) => setActiveIngredient(e.target.value)}
            />
          </label>
          <label>
            {t("Pharmaceutical Form")}:
            <input
                type="text"
                placeholder={t("Enter pharmaceutical form")}
                value={pharmaceuticalForm}
                onChange={(e) => setPharmaceuticalForm(e.target.value)}
            />
          </label>
          <button onClick={handleReset} style={{marginBottom: "10px"}}>
            {t("Reset Search")}
          </button>
          <button onClick={handleSearchClick}>{t("Search")}</button>
        </div>
      </div>
  );
}

export default TuberkulinPure;
