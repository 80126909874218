import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/newPhoto/pasterelioz.jpg";

function Pasterelioz() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };
const handleDownload = () => {
  const pdfPath = "/pdf/pdf_vkladki/ЛИСТІВКА-ВКЛАДКА_ПАСТЕРЕЛЬОЗ.PDF"; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  return (
      <div className="descriptionPage">
        <div className="productDetails">
          <div className="title_image">
            <h1>{t("Pasteurella Vaccine for Birds")}</h1>
            <img src={Cat} alt="Rodent Control Product" className="productImage"/>
          </div>
          <div className="productExtraInfo">
            <p>
              <strong>{t("Product Name")}:</strong> {t("Inactivated Absorbed Pasteurella Vaccine for Birds")}
            </p>
            <p><strong>{t("Price per vial (100 ml)")}</strong>: 496.80 {t("UAH")}</p>
            <p><strong>{t("Minimum order")}</strong>: {t("1 vial")}</p>
            <p><strong>{t("Availability")}</strong>: {t("In stock")}</p>
            <h3>{t("Composition")}</h3>
            <ul>
              <li>{t("Active ingredients: Pasteurella multocida, strain №115 4×1010 bacteria/cm³ before inactivation - 50%.")}</li>
              <li>{t("Excipients: Inactivator: formalin 0.05%; Adjuvant: Aluminum hydroxide (3% solution) 50%.")}</li>
            </ul>

            <h3>{t("Pharmaceutical Form")}</h3>
            <p>{t("Whitish-gray suspension with a white sediment that forms at the bottom of the bottle during storage, easily broken up into a homogeneous suspension upon shaking.")}</p>

            <h3>{t("Immunobiological Properties")}</h3>
            <p>{t("Immunity is formed within 14–21 days and lasts for 6–8 months.")}</p>

            <h3>{t("Target Animals")}</h3>
            <p>{t("Chickens, turkeys, ducks, geese.")}</p>

            <h3>{t("Indications for Use")}</h3>
            <p>{t("Used for the prevention of avian pasteurellosis in farms affected by pasteurellosis.")}</p>

            <h3>{t("Contraindications")}</h3>
            <p>{t("Vaccination is strictly prohibited for sick or suspected sick birds.")}</p>

            <h3>{t("Precautions for Use")}</h3>
            <ul>
              <li>{t("Only clinically healthy chickens, turkeys, geese, ducks should be vaccinated.")}</li>
              <li>{t("Before and during use, shake vaccine bottles until a homogeneous suspension is formed.")}</li>
            </ul>

            <h3>{t("Interaction with Other Products")}</h3>
            <p>{t("For preventing avian pasteurellosis during the immunity development period, combined use of the vaccine with antibiotics of the tetracycline group or levomycetin in doses of 50-60 mg/kg body weight for 5 days with feed is recommended.")}</p>

            <h3>{t("Special Instructions During Laying")}</h3>
            <p>{t("No restrictions.")}</p>

            <h3>{t("Method of Application and Dosage")}</h3>
            <p>{t("Only clinically healthy chickens, turkeys, geese, starting from 30 days of age, and ducks from 15 days of age, should be vaccinated. Sick or suspected sick birds should be culled.")}</p>
            <ul>
              <li>{t("Chickens (egg breeds): 30–60 days – 0.5 cm³; 61–120 days – 1.0 cm³; 121 days and older – 2.0 cm³;")}</li>
              <li>{t("Chickens (meat breeds): 30–60 days – 0.5 cm³; 61–90 days – 1.0 cm³; 91 days and older – 2.0 cm³;")}</li>
              <li>{t("Turkeys: 30 days – 0.5 cm³; 31–60 days – 1.0 cm³; 61–120 days – 2.0 cm³; 121 days and older – 4.0 cm³;")}</li>
              <li>{t("Ducks: 15 days – 0.5 cm³; 30–60 days – 1.0 cm³; 61 days and older – 2.0 cm³;")}</li>
              <li>{t("Geese: 30 days – 0.5 cm³; 31–60 days – 1.0 cm³; 61–120 days – 2.0 cm³; 121 days and older – 4.0 cm³;")}</li>
            </ul>

            <h3>{t("Revaccination")}</h3>
            <p>{t("Revaccinate turkeys after 6 months, chickens, ducks, and geese after 6–8 months.")}</p>

            <h3>{t("Side Effects")}</h3>
            <p>{t("None detected.")}</p>

            <h3>{t("Withdrawal Period")}</h3>
            <p>{t("Zero days.")}</p>

            <h3>{t("Special Precautions for Personnel Handling the Vaccine")}</h3>
            <p>{t("Before vaccination, disinfect premises and air according to the 'Instructions for aerosol disinfection of poultry premises in the presence of poultry.'")}</p>

            <h3>{t("Special Safety Measures for Handling Unused Vaccine")}</h3>
            <p>{t("Vials with the vaccine without labels, containing mold, cracks, foreign impurities, that do not break upon shaking, with broken seals, frozen, or with color changes should be discarded and destroyed by boiling for 30 minutes or autoclaving at 132°C for 30 minutes.")}</p>

            <h3>{t("Shelf Life")}</h3>
            <ul>
              <li>{t("18 months. Use within 4 hours after opening the vial.")}</li>
            </ul>

            <h3>{t("Storage and Transportation Conditions")}</h3>
            <p>{t("Store in a dry, dark place at a temperature from 2°C to 8°C.")}</p>

            <h3>{t("Packaging")}</h3>
            <ul>
              <li>{t("Glass vials of 10 cm³, 20 cm³, 50 cm³, 100 cm³, 200 cm³.")}</li>
            </ul>

            <h3>{t("Name and Location of the Registration Certificate Holder and Manufacturer")}</h3>
            <p>{t("State Enterprise 'Sumy Biological Factory,' 40021, Sumy, Hamalia St., 25, Ukraine, Tel/Fax: (0542) 61-70-08.")}</p>

            <h3>{t("Rules for Dispensing")}</h3>
            <p>{t("Without a prescription.")}</p>

            <h3>{t("Additional Information")}</h3>
            <p>{t("If the product does not meet the requirements of the leaflet or complications arise, the use of this batch should be immediately stopped, and the State Scientific-Control Institute of Biotechnology and Microorganism Strains (SSCIB) and the supplier (manufacturer) must be notified. Simultaneously, three unopened vials of this batch should be sent to SSCIB at 03151, Kyiv, Donetska St., 30, SSCIB.")}</p>
            <div className="productDownloadRegistrationCert">
              <button onClick={handleDownload} className="productDownloadRegistrationCert">
                {t("Download Product Registration")}
              </button>
            </div>
            <div className="productDownloadRegistrationCert">
              <Link to="/contact" className="productDownloadRegistrationCert">
                {t("Check availability")}
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar">
          <h2>{t("Search the Product")}</h2>
          <label>
            {t("Animal")}:
            <select
                value={selectedAnimal}
                onChange={(e) => setSelectedAnimal(e.target.value)}
            >
              <option value="all">{t("another")}</option>

              <option value="pig">{t("Pig")}</option>
              <option value="cattle">{t("Cattle")}</option>

              <option value="poultry">{t("Poultry")}</option>


              <option value="rabbit">{t("Rabbit")}</option>

              <option value="rodent">{t("Rodents")}</option>

            </select>
          </label>
          <label>
            {t("Category")}:
            <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="all">{t("All")}</option>
              <option value="vaccines">{t("Vaccines")}</option>
              <option value="diagnostics">{t("Diagnostics")}</option>
              <option value="rodenticides">{t("Rodenticides")}</option>
              <option value="test-kits">{t("Test Kits")}</option>
              <option
                  value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
              <option value="experimental-drugs">{t("Experimental Drugs")}</option>

            </select>
          </label>
          <label>
            {t("Product Name")}:
            <input
                type="text"
                placeholder={t("Enter product name")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </label>
          <label>
            {t("Active Ingredient")}:
            <input
                type="text"
                placeholder={t("Enter active ingredient")}
                value={activeIngredient}
                onChange={(e) => setActiveIngredient(e.target.value)}
            />
          </label>
          <label>
            {t("Pharmaceutical Form")}:
            <input
                type="text"
                placeholder={t("Enter pharmaceutical form")}
                value={pharmaceuticalForm}
                onChange={(e) => setPharmaceuticalForm(e.target.value)}
            />
          </label>
          <button onClick={handleReset} style={{marginBottom: "10px"}}>
            {t("Reset Search")}
          </button>
          <button onClick={handleSearchClick}>{t("Search")}</button>
        </div>
      </div>
  );
}

export default Pasterelioz;
