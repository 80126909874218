import React, { useState } from "react";
import "../components/styles/Contact.css";
import { useTranslation } from "react-i18next"; // Import useTranslation

function Contact() {
  const { t } = useTranslation(); // Access translation function
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "", // Added phone field
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch("https://react-site-lp1i.onrender.com/send-contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert(t("Message sent successfully!"));
      setFormData({ name: "", email: "", phone: "", message: "" }); // Clear the form
    } else {
      alert(t("Failed to send message. Please try again."));
    }
  } catch (error) {
    console.error("Error:", error);
    alert(t("An error occurred. Please try again."));
  }
};


  return (
    <div className="contactMain">
      <div className="leftSide"></div>
      <div className="rightSideContact">
        <h1>{t("Contact Us")}</h1>
        <form id="contact-form" onSubmit={handleSubmit}>
          <label htmlFor="name">{t("Full Name")}</label>
          <input
            name="name"
            placeholder={t("Enter full name...")}
            type="text"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <label htmlFor="email">{t("Email")}</label>
          <input
            name="email"
            placeholder={t("Enter email...")}
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <label htmlFor="phone">{t("Phone Number")}</label>
          <input
            name="phone"
            placeholder={t("Enter phone number...")}
            type="tel"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <label htmlFor="message">{t("Message")}</label>
          <textarea
            rows="6"
            placeholder={t("Enter message...")}
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit">{t("Send Message")}</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
