import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import BannerImage from "../assets/cows.jpg";
import { MenuList } from "../helper/MenuList"; // Import your product list
import "../components/styles/Home.css";
import {useTranslation} from "react-i18next";

// Function to generate 5 random products
const generateRandomItems = () => {
  return [...MenuList].sort(() => 0.5 - Math.random()).slice(0, 5);
};

function Home() {
    const { t } = useTranslation();

  const [currentItems, setCurrentItems] = useState(generateRandomItems());
  const navigate = useNavigate(); // Initialize navigation

  // Handle clicking the Next button
 const handleNext = () => {
  // Filter out item with id 14
  const filteredMenuList = MenuList.filter((item) => item.id !== 14);

  // Get a random item from the filtered list
  const newRandomItem = filteredMenuList[Math.floor(Math.random() * filteredMenuList.length)];

  setCurrentItems((prevItems) => {
    const updatedItems = [...prevItems.slice(1), newRandomItem];
    return updatedItems;
  });
};

  // Handle clicking the Prev button
  const handlePrev = () => {
    const newRandomItem = MenuList[Math.floor(Math.random() * MenuList.length)];
    setCurrentItems((prevItems) => {
      const updatedItems = [newRandomItem, ...prevItems.slice(0, -1)];
      return updatedItems;
    });
  };

  // Handle navigation to details page
  const handleDetailsClick = (id) => {
    navigate(`/description/${id}`); // Navigate to the product details page with its ID
  };

  return (
    <div className="home">
      {/* Display BannerImage as a standalone photo */}
      <div className="bannerImageWrapper">
        <img src={BannerImage} alt="Cows" className="bannerImage" />
      </div>

      {/* Carousel Section */}
      <div className="carouselWrapper">
        <h2>{t("Our products")}</h2>
        <div className="carouselContainer">
          <button className="carouselButton" onClick={handlePrev}>
            ◀
          </button>
          <div className="carouselItems">
            {currentItems.map((item, index) => (
              <div className="carouselItem" key={index}>
                <img src={item.image} alt={t(item.nameText)} />
                <h3>{t(item.nameText)}</h3>
<p className="HomeCategory">
  {t("Category")}: {t(item.categoryText)}
</p>
                <button
                    className="detailsButton"
                  onClick={() => handleDetailsClick(item.id)} // Pass the item ID
                >
                  {t("Details")}
                </button>
              </div>
            ))}
          </div>
          <button className="carouselButton" onClick={handleNext}>
            ▶
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
