import React, { useState } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import "../components/styles/Description.css";
import { useTranslation } from "react-i18next";
import Cat from "../assets/newPhoto/banka.jpg";


function Aluminiy() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAnimal, setSelectedAnimal] = useState("all");
  const [activeIngredient, setActiveIngredient] = useState("");
  const [pharmaceuticalForm, setPharmaceuticalForm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };
const handleDownload = () => {
  const pdfPath = "/pdf/pdf_vkladki/ДЕРАТЕЗ.PDF"; // Correct path to the file
  const link = document.createElement('a');
  link.href = encodeURI(pdfPath);  // Encode the URI to handle special characters
  link.setAttribute('download', pdfPath.split('/').pop()); // Extracts the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
  const handleSearchClick = () => {
    navigate("/menu", {
      state: {
        searchQuery: searchTerm,
        selectedCategory,
        selectedAnimal,
        activeIngredient,
        pharmaceuticalForm,
      },
    });
  };

  const handleReset = () => {
    setSearchTerm("");
    setSelectedCategory("all");
    setSelectedAnimal("all");
    setActiveIngredient("");
    setPharmaceuticalForm("");
  };

  return (
      <div className="descriptionPage">
        <div className="productDetails">
          <div className="title_image">
            <h1>{t("Aluminum Hydroxide Gel 5-7% (Hydroxal)")}</h1>
            <img src={Cat} alt="Rodent Control Product" className="productImage"/>
          </div>
            <div className="productExtraInfo">

                <section className="gel-details">
                    <h2>{t("Composition")}</h2>
                    <p>{t("The gel contains 5-7% aluminum hydroxide by weight.")}</p>
                </section>
                <section className="gel-pricing">
                    <h2>{t("Price:")}</h2>
                    <p>{t("Price per liter: 2100.00 UAH")}</p>
                    <h2>{t("Availability")}</h2>
                    <p>{t("In stock")}</p>
                </section>
                <section className="gel-form">
                    <h2>{t("Pharmaceutical Form:")}</h2>
                    <p>
                        {t(
                            "Aluminum hydroxide gel is a homogeneous suspension of white with a grayish tint. Upon settling, it separates into a clear supernatant liquid and sediment. After shaking or mixing, no conglomerates remain on the walls of the container."
                        )}
                    </p>
                </section>
                <section className="gel-properties">
                    <h2>{t("Pharmacological Properties:")}</h2>
                    <p>
                        {t(
                            "Designed for deposition and adsorption of proteins, toxins, bacterial and viral suspensions."
                        )}
                    </p>
                </section>
                <section className="gel-indications">
                    <h2>{t("Indications")}</h2>
                    <p>
                        {t(
                            "Used as an adsorbent in the production of biological preparations for veterinary medicine."
                        )}
                    </p>
                </section>
                <section className="gel-interactions">
                    <h2>{t("Interactions with Other Agents:")}</h2>
                    <p>{t("Used in biotechnology in optimal proportions.")}</p>
                </section>
                <section className="gel-usage">
                    <h2>{t("Usage and Dosage:")}</h2>
                    <p>
                        {t(
                            "Used as an adsorbent in the production of biological preparations for veterinary practices."
                        )}
                    </p>
                </section>
                <section className="gel-shelf-life">
                    <h2>{t("Shelf Life:")}</h2>
                    <p>{t("12 months")}</p>
                </section>
                <section className="gel-storage">
                    <h2>{t("Storage Conditions:")}</h2>
                    <p>
                        {t(
                            "Store in the manufacturer's packaging in a closed, dry, dark room at a temperature between +10°C and 25°C. Freezing is not allowed."
                        )}
                    </p>
                </section>


                {/*<div className="productDownloadRegistrationCert">*/}
                {/*  <button onClick={handleDownload} className="productDownloadRegistrationCert">*/}
                {/*    {t("Download Product Registration")}*/}
                {/*  </button>*/}
                {/*</div>*/}
                <div className="productDownloadRegistrationCert">
                    <Link to="/contact" className="productDownloadRegistrationCert">
                        {t("Check availability")}
                    </Link>
                </div>
            </div>
        </div>
          <div className="sidebar">
              <h2>{t("Search the Product")}</h2>
              <label>
                  {t("Animal")}:
                  <select
                      value={selectedAnimal}
                      onChange={(e) => setSelectedAnimal(e.target.value)}
                  >
                      <option value="all">{t("another")}</option>

                      <option value="pig">{t("Pig")}</option>
                      <option value="cattle">{t("Cattle")}</option>

                      <option value="poultry">{t("Poultry")}</option>


                      <option value="rabbit">{t("Rabbit")}</option>

                      <option value="rodent">{t("Rodents")}</option>

                  </select>
              </label>
              <label>
                  {t("Category")}:
                  <select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                      <option value="all">{t("All")}</option>
                      <option value="vaccines">{t("Vaccines")}</option>
                      <option value="diagnostics">{t("Diagnostics")}</option>
                      <option value="rodenticides">{t("Rodenticides")}</option>
                      <option value="test-kits">{t("Test Kits")}</option>
                      <option
                          value="antiseptics-disinfectants-probiotics">{t("Antiseptics, Disinfectants, Probiotics, and Others")}</option>
                      <option value="experimental-drugs">{t("Experimental Drugs")}</option>

                  </select>
              </label>
              <label>
                  {t("Product Name")}:
                  <input
                      type="text"
                      placeholder={t("Enter product name")}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                  />
              </label>
              <label>
                  {t("Active Ingredient")}:
                  <input
                      type="text"
                      placeholder={t("Enter active ingredient")}
                      value={activeIngredient}
                      onChange={(e) => setActiveIngredient(e.target.value)}
                  />
              </label>
              <label>
                  {t("Pharmaceutical Form")}:
                  <input
                      type="text"
                      placeholder={t("Enter pharmaceutical form")}
                      value={pharmaceuticalForm}
                onChange={(e) => setPharmaceuticalForm(e.target.value)}
            />
          </label>
          <button onClick={handleReset} style={{marginBottom: "10px"}}>
            {t("Reset Search")}
          </button>
          <button onClick={handleSearchClick}>{t("Search")}</button>
        </div>
      </div>
  );
}

export default Aluminiy;
